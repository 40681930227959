import React, { useState, useEffect } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import Helmet from "react-helmet";
import { navigate } from "gatsby";

// Components
import { Page, AccountContainer } from "../components/trade/trade-components";
import {
	Fields,
	ContentContainer,
} from "../components/account/login-components";

const CUSTOMER_RESET_PASSWORD = gql`
	mutation resetPasswordByUrl($resetUrl: URL!, $password: String!) {
		customerResetByUrl(resetUrl: $resetUrl, password: $password) {
			customer {
				id
			}
			customerUserErrors {
				code
				field
				message
			}
		}
	}
`;

const ResetPassword = ({ location }) => {
	const [newPasswordInput, setNewPasswordInput] = useState("");
	const [resetUrl, setResetUrl] = useState(null);

	useEffect(() => {
		let url = new URL(location.href);
		let query = url.searchParams.get("reset_url");
		setResetUrl(query);
	}, [location]);

	return (
		<>
			<Mutation mutation={CUSTOMER_RESET_PASSWORD}>
				{(customerResetByUrl) => {
					return (
						<Page>
							<Helmet title={`Reset Password | Christian Watson`} />

							<AccountContainer>
								<ContentContainer>
									<section>
										<h1>Reset Password</h1>

										<Fields>
											<label>
												Please enter a new password. You will be automatically
												redirected to the account page once your password has
												been reset.
											</label>
											<br />
											<br />
											<input
												className="input"
												value={newPasswordInput}
												type="password"
												onChange={(e) => setNewPasswordInput(e.target.value)}
												placeholder="New Password"
												required
											/>

											<button
												disabled={resetUrl === null || newPasswordInput === ""}
												onClick={(e) => {
													e.preventDefault();
													customerResetByUrl({
														variables: {
															resetUrl: resetUrl,
															password: newPasswordInput,
														},
													}).then((result) => {
														navigate(`/account/login/`);
													});
												}}
											>
												Submit Password
											</button>
										</Fields>
									</section>
								</ContentContainer>
							</AccountContainer>
						</Page>
					);
				}}
			</Mutation>
		</>
	);
};

export default ResetPassword;
